// ** Icons Import
import {
  Circle,
  Shield,
  Archive,
  Grid,
  Server,
  Clipboard,
  Upload,
  Twitch,
  File,
  FileText
} from 'react-feather'
import { IS_ADMIN, IS_MENTOR, IS_RESOURCE } from '../../utility/constants'

export default [
  // {
  //   header: 'My Team',
  //   show: [IS_ADMIN, IS_MENTOR]
  // },
  // {
  //   id: 'session',
  //   title: 'Sessions',
  //   icon: <Twitch size={10} />,
  //   show: [IS_RESOURCE, IS_ADMIN],
  //   navLink: '/apps/session'
  // },

  {
    id: 'Reports',
    title: 'Reports',
    icon: <FileText size={20} />,
    show: [IS_ADMIN, IS_MENTOR], //IS_RESOURCE
    children: [
      {
        id: 'Coming Soon',
        title: 'Reviews Not Done',
        badge: 'light-warning',
        navLink: '/reviews-not-done',
        show: [IS_ADMIN, IS_MENTOR] //IS_RESOURCE
      }
    ]
  }
]
