// ** Redux Imports
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import { baseUrl } from '../../../../../app.config'

// ** Axios Imports
import axios from 'axios'
import toast from 'react-hot-toast'

export const getUserList = createAsyncThunk(
  'user/getUserList',
  async (params) => {
    let { url, ...rest } = params
    const response = await axios.get(baseUrl() + '/user' + (url ?? ''), {
      params: { ...rest }
    })
    return {
      // totalPages: response.data.count.total,  // 1s we receved count.total from backend for deleteUser api we need to uncomment this code and remove the below code  response.data.data.length,
      totalPages: response?.data?.count?.total, // this is just for now to make deleteUser api working as it dont have .count.total,
      params: params,
      data: response.data.data
    }
  }
)

export const getAllCountry = createAsyncThunk(
  'user/getAllCountry',
  async (params) => {
    const response = await axios.get(baseUrl() + '/country', { params })
    return {
      data: response.data.data
    }
  }
)

export const getAllState = createAsyncThunk('user/getAllState', async (id) => {
  const response = await axios.get(baseUrl() + `/state?countryId=${id}`)
  return {
    data: response.data.data
  }
})

export const getAllCity = createAsyncThunk('user/getAllCity', async (id) => {
  const response = await axios.get(baseUrl() + `/city?stateId=${id}`)
  return {
    data: response.data.data
  }
})

export const getAllUserList = createAsyncThunk(
  'user/getAllUserList',
  async (id) => {
    const response = await axios.get(baseUrl() + '/user/all', { id }) // why id has been passed here for getting all data ??
    return {
      userAllList: response.data.data
    }
  }
)

export const getUserData = createAsyncThunk(
  'user/getUserData',
  async ({ userId }, { dispatch }) => {
    // dispatch(setLoader(true))
    dispatch(setDetailsLoader(true))
    const userTechnologies = await axios.get(
      baseUrl() + `/user/${userId}/technologies`
    )
    const userReview = await axios.get(
      baseUrl() + `/user-review?userId=${userId}`
    )
    const userDetail = await axios.get(baseUrl() + `/user/${userId}`)
    dispatch(setDetailsLoader(false))
    return {
      userTechnologies: userTechnologies.data.data,
      userListById: userDetail.data.data,
      //Commenting
      //totalPages: userReview?.data.count.total,
      // params: userReview?.data.params,
      userReview: userReview?.data.data,
      loader: false
    }
  }
)

export const getTechnologyByUserId = createAsyncThunk(
  'user/getTechnologyByUserId',
  async ({ userId }) => {
    const response = await axios.get(baseUrl() + `/user/${userId}/technologies`)
    return {
      data: response.data.data
    }
  }
)
export const getUserListById = createAsyncThunk(
  'user/getUserListById',

  async ({ userId }) => {
    const response = await axios.get(baseUrl() + `/user/${userId}`)

    return {
      userListById: response.data.data
    }
  }
)

export const addMentorUser = createAsyncThunk(
  'addMentorUser',
  async (user, { dispatch, getState }) => {
    await axios.post(baseUrl() + '/user-mentor', user)
    return user
  }
)

export const getAllMentor = createAsyncThunk('getAllMentor', async (id) => {
  const response = await axios.get(baseUrl() + '/user-mentor')
  return {
    mentorAllList: response.data.data
  }
})

export const addUserReview = createAsyncThunk(
  'user/addUserReview',
  async (payload, { dispatch }) => {
    try {
      const res = await axios.post(baseUrl() + '/user-review', payload)
      toast.success(res?.data?.message)
      dispatch(
        getUserReviewList({
          userId: payload.userId
        })
      )
      return true
    } catch (error) {
      toast.error(error?.response?.data?.message)
      return false
    }
  }
)

export const getUserReviewList = createAsyncThunk(
  'user/getUserReviewList',
  async (params) => {
    // dispatch(setLoader())
    const response = await axios.get(baseUrl() + '/user-review', { params })
    return {
      // totalPages: response.data.count.total,
      // params: response.data.params,
      data: response?.data?.data,
      loader: false
    }
  }
)

export const addUser = createAsyncThunk(
  'user/addUser',
  async ({ payload, params }, { pageNo, pageSize, dispatch, getState }) => {
    try {
      const res = await axios.post(baseUrl() + '/user', payload)
      toast.success(res?.data?.message)
      dispatch(getUserList({ ...params }))
      return true
    } catch (error) {
      toast.error(error?.response?.data?.message)
      return false
    }
  }
)

export const updateUser = createAsyncThunk(
  'user/updateUser',
  async ({ id, payload }, { dispatch }) => {
    try {
      const res = await axios.put(baseUrl() + `/user/${id}`, {
        ...payload
      })
      toast.success(res?.data?.message)
      return true
    } catch (error) {
      toast.error(error?.response?.data?.message)
      return false
    }
  }
)

export const deleteUser = createAsyncThunk(
  'user/deleteUser',
  async ({ id, pageNo, pageSize, params }, { dispatch, getState }) => {
    try {
      const res = await axios.delete(baseUrl() + `/user/delete/${id}`)
      toast.success(res.data.message)
      await dispatch(getUserList({ pageNo, pageSize, ...params }))
      return id
    } catch (error) {
      toast.error(error?.response?.data?.message)
    }
  }
)

export const updatePayrollStatus = createAsyncThunk(
  'user/updatePayrollStatus',
  async ({ id, payroll, params }, { dispatch }) => {
    try {
      const res = await axios.patch(baseUrl() + `/user/${id}`, { payroll })
      toast.success(res?.data?.message)
      await dispatch(getUserList({ ...params }))
    } catch (error) {
      toast.error(error?.response?.data?.message)
    }
  }
)

export const updateEfficiencyLevel = createAsyncThunk(
  'user/updateEfficiencyLevel',
  async ({ id, efficiency, params }, { dispatch }) => {
    try {
      const res = await axios.patch(baseUrl() + `/user/efficiency/${id}`, {
        efficiency
      })
      toast.success(res?.data?.message)
      await dispatch(getUserList({ ...params }))
    } catch (error) {
      toast.error(error?.response?.data?.message)
    }
  }
)

export const getLogsData = createAsyncThunk('user/getLogsData', async (id) => {
  try {
    const res = await axios.get(baseUrl() + `/user/userreviewlogger/${id}`)
    console.log('res', res)
    return res?.data?.data || []
  } catch (error) {
    toast.error(error?.response?.data?.message)
  }
})

export const sendEmailTemplate = createAsyncThunk(
  'user/sendEmailTemplate',
  async (id) => {
    try {
      const res = await axios.get(baseUrl() + `/user/sendpipmail/${id}`)
      toast.success(res?.data?.message)
      return res?.data?.data || []
    } catch (error) {
      toast.error(error?.response?.data?.message)
    }
  }
)

// export const updateEmailTemplate = createAsyncThunk(
//   'user/updateEmailTemplate',
//   async ({ id, payload }, { dispatch }) => {
//     try {
//       await axios.put(baseUrl() + `/mailTemplate/${id}`, {
//         ...payload
//       }),
//         toast.success('Template Updated successfully')
//       return true
//     } catch (error) {
//       toast.error(error?.response?.data?.message)
//       return false
//     }
//   }
// )
export const getAllMailTemplate = createAsyncThunk(
  '/mailTemplate',
  async () => {
    const response = await axios.get(baseUrl() + '/mailTemplate')
    return {
      data: response?.data?.data ? response?.data?.data : []
    }
  }
)

export const updateEmailTemplate = createAsyncThunk(
  'user/updateEmailTemplate',
  async ({ id, formData }, { dispatch }) => {
    try {
      const res = await axios.put(baseUrl() + `/mailTemplate/${id}`, formData, {
        headers: { 'Content-Type': 'multipart/form-data' }
      })
      toast.success(res?.data?.message)
      return true
    } catch (error) {
      toast.error(error?.response?.data?.message)
    }
  }
)

export const user = createSlice({
  name: 'user',
  initialState: {
    totalPages: 0,
    userList: [],
    userAllList: [],
    mentorAllList: [],
    userListById: {},
    userReviewList: [],
    countryList: [],
    stateList: [],
    cityList: [],
    selectUser: {},
    technologyListById: [],
    allDeletedUsers: [],
    showDeletedUser: false,
    reload: false,
    toaster: '',
    loading: false,
    clearFilter: false,
    loader: false,
    formLoader: false,
    detailsLoader: false,
    allMailTemplate: [],
    params: {
      pageNo: 1,
      pageSize: 10
    },
    logsData: {
      loader: false,
      data: []
    }
  },
  reducers: {
    setUserDetails: (state, action) => {
      state.userListById = {}
      state.userReviewList = []
    },
    setSelectUser: (state, action) => {
      if (action.payload === null) {
        state.selectUser = null
      } else {
        state.selectUser = action.payload
      }
    },
    setLoading: (state) => {
      state.loading = !state.loading
    },
    selectClearFilter: (state, action) => {
      state.clearFilter = action.payload
    },
    setDetailsLoader: (state, action) => {
      state.detailsLoader = action.payload
    },
    setLoader: (state) => {
      state.loader = !state.loader
    },
    setFormLoader: (state, action) => {
      state.formLoader = action.payload
    },
    setParams: (state, action) => {
      state.params = {
        ...state.params,
        ...action.payload
      }
    },
    setShowDeletedUser: (state, action) => {
      state.showDeletedUser = action.payload
    }
  },

  extraReducers: {
    [addUser.fulfilled]: (state, action) => {
      state.reload = !state.reload
      state.toaster = 'User Added Successfully'
    },
    [getUserList.fulfilled]: (state, action) => {
      state.userList = action.payload.data
      state.totalPages = action.payload.totalPages
      state.params = action.payload.params
      state.loader = false
    },
    [getUserList.pending]: (state, action) => {
      state.loader = true
    },
    [getAllCountry.fulfilled]: (state, action) => {
      state.countryList = action.payload.data
    },
    [getAllState.fulfilled]: (state, action) => {
      state.stateList = action.payload.data
    },
    [getAllCity.fulfilled]: (state, action) => {
      state.cityList = action.payload.data
    },
    [getAllUserList.fulfilled]: (state, action) => {
      state.userAllList = action.payload
    },
    [getAllMentor.fulfilled]: (state, action) => {
      state.mentorAllList = action.payload
    },
    [getUserListById.fulfilled]: (state, action) => {
      state.userListById = action.payload
    },
    [getTechnologyByUserId.fulfilled]: (state, action) => {
      state.technologyListById = action.payload.data
    },
    [getUserReviewList.fulfilled]: (state, action) => {
      state.userReviewList = action.payload.data
      state.loader = action.payload.loader
    },
    [deleteUser.fulfilled]: (state, action) => {
      state.reload = !state.reload
    },
    [getUserData.fulfilled]: (state, action) => {
      state.technologyListById = action.payload.userTechnologies
      state.userReviewList = action.payload.userReview
      state.userListById = action.payload.userListById
      // state.loader = false
    },
    [getLogsData.pending]: (state, action) => {
      state.logsData.loader = true
    },
    [getLogsData.fulfilled]: (state, action) => {
      state.logsData.data = action.payload
      state.logsData.loader = false
    },
    [getLogsData.rejected]: (state, action) => {
      state.logsData.loader = false
    },
    [getAllMailTemplate.fulfilled]: (state, action) => {
      state.allMailTemplate = action.payload.data
    }
  }
})

export const {
  setSelectUser,
  setLoading,
  selectClearFilter,
  setLoader,
  setDetailsLoader,
  setParams,
  setFormLoader,
  setShowDeletedUser,
  setUserDetails
} = user.actions

export default user.reducer
